import Experience from "../Experience";
import canvasRecord from "canvas-record";

export default class VideoCapture {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.setRecorder();
    this.setDebug();
  }

  setRecorder() {
    try {
      this.canvasRecorder = canvasRecord(
        document.querySelector("canvas.webgl"),
        {
          recorderOptions: {
            videoBitsPerSecond: 6000000,
          },
        }
      );
    } catch (err) {
      this.canvasRecorder = canvasRecord(
        document.querySelector("canvas.webgl"),
        {
          recorderOptions: {
            videoBitsPerSecond: 6000000,
            mimeType: "video/mp4",
          },
        }
      );
    }
  }
  setDebug() {
    this.startButton = this.debug.actionsFolder
      .addButton({
        title: "start recording",
      })
      .on("click", () => {
        this.startRecording();
      });
    this.stopButton = this.debug.actionsFolder
      .addButton({
        title: "stop recording",
        hidden: true,
      })
      .on("click", () => {
        this.stopRecording();
      });
  }

  startRecording() {
    this.canvasRecorder.start();
    this.startButton.hidden = true;
    this.stopButton.hidden = false;
  }
  stopRecording() {
    this.startButton.hidden = false;
    this.stopButton.hidden = true;
    this.canvasRecorder.stop();
  }
}
