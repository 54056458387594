import * as THREE from "three";
import Sizes from "./Utils/Sizes";
import Time from "./Utils/Time";
import Camera from "./Camera";
import Renderer from "./Renderer";
import World from "./World/World";
import Debug from "./Utils/Debug";
import Capture from "./Utils/Capture";
import VideoCapture from "./Utils/VideoCapture";

let instance = null;

export default class Experience {
  constructor(canvas) {
    // console.log("Experience starting...");

    //turns this class into singleton: it's instantiated once, and for next times it returns the first instance (copy)
    if (instance) {
      return instance;
    }

    instance = this;

    //Options
    this.canvas = canvas;

    //Setup
    this.debug = new Debug();
    this.sizes = new Sizes();
    this.time = new Time();
    this.scene = new THREE.Scene();

    this.camera = new Camera(false, false);
    this.renderer = new Renderer();
    this.world = new World();
    this.capture = new Capture();
    this.videoCapture = new VideoCapture();

    //Resize Event from Sizes class
    this.sizes.on("resize", () => {
      this.resize();
    });

    //Tick Event from Time class
    this.time.on("tick", () => {
      this.update();
    });
  }

  resize() {
    this.camera.resize();
    this.renderer.resize();
  }
  update() {
    this.camera.update();
    this.renderer.update();
    this.world.update();
  }
}
