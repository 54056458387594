import * as THREE from "three";
import Experience from "../Experience";
import vertexShader from "./shaders/vertex.glsl";
import fragmentShader from "./shaders/fragment.glsl";
import colorPallete from "nice-color-palettes";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.debug = this.experience.debug;
    this.time = this.experience.time;
    this.canvas = this.experience.canvas;
    this.colors = colorPallete[20];
    this.currentColors = colorPallete[20];
    this.newColors = [];

    this.convertColors();
    this.initGradient();
    this.setDebug();
  }

  createColors() {
    this.newColors =
      colorPallete[Math.floor(Math.random() * colorPallete.length)];
    this.currentColors = this.newColors;
  }

  getColors() {
    for (let i = 0; i < this.colors.length; i++) {
      this.currentColors[i] = localStorage.getItem(`color${i}`);
    }
    this.colors = this.currentColors;
    this.convertColors();
  }

  convertColors() {
    this.currentColors = this.currentColors.map(
      (color) => new THREE.Color(color)
    );
  }

  randomizeColors() {
    this.createColors();
    this.convertColors();
    this.updateDebug();

    this.material.uniforms.uColor.value = this.currentColors;
  }

  initGradient() {
    this.material = new THREE.ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      uniforms: {
        uFrequency: { value: new THREE.Vector2(3, 6) },
        uTime: { value: 0 },
        uColor: { value: this.currentColors },
        uAmount: { value: 0.2 },
        uSpeed: { value: 0.02 },
      },
    });
    this.geometry = new THREE.PlaneGeometry(1.5, 1.5, 250, 250);

    const plane = new THREE.Mesh(this.geometry, this.material);

    this.scene.add(plane);
  }

  setDebug() {
    for (let color in this.colors) {
      this.debug.gradientFolder.addInput(this.currentColors, color, {
        color: { type: "float" },

        label: `color${color}`,
      });
    }
    this.debug.renderFolder.addInput(this.material, "wireframe", {
      label: "wireframe",
    });

    this.debug.actionsFolder
      .addButton({
        title: "random colors",
      })
      .on("click", () => {
        this.randomizeColors();
      });

    this.debug.noiseFolder.addInput(this.material.uniforms.uAmount, "value", {
      min: 0,
      max: 0.6,
      step: 0.01,
      label: "amount",
      presetKey: "amount",
    });
    this.debug.noiseFolder.addInput(
      this.material.uniforms.uFrequency.value,
      "x",
      {
        min: 0,
        max: 7,
        step: 0.01,
        label: "frequency",
        presetKey: "frequency",
      }
    );
    this.debug.noiseFolder.addInput(this.material.uniforms.uSpeed, "value", {
      min: 0,
      max: 0.3,
      step: 0.01,
      label: "speed",
      presetKey: "speed",
    });
  }

  // saveConfig() {
  //   this.config = this.debug.pane.exportPreset();
  //   console.log(this.config);

  //   localStorage.setItem("config", JSON.stringify(this.config));
  // }
  // importConfig() {
  //   this.newConfig = localStorage.getItem("config");
  //   this.parsedConfig = JSON.parse(this.newConfig);
  //   console.log(this.parsedConfig);
  //   this.debug.pane.importPreset(this.parsedConfig);
  // }

  clearDebug() {
    this.debug.gradientFolder.children.forEach((child) => child.dispose());
  }

  updateDebug() {
    this.clearDebug();

    for (let color in this.currentColors) {
      this.debug.gradientFolder.addInput(this.currentColors, color, {
        color: { type: "float" },

        label: `color${color}`,
      });
    }
  }

  update() {
    this.material.uniforms.uTime.value = this.time.elapsed * 0.001;
  }
}
