import Experience from "../Experience";

export default class Capture {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.setDebug();
  }

  setDebug() {
    //setDebug
    // this.actionsFolder = this.debug.pane.addFolder({ title: "Actions" });
    this.debug.actionsFolder
      .addButton({
        title: "export image",
      })
      .on("click", () => {
        this.saveCanvas();
      });
  }

  saveCanvas() {
    console.log("save png");
    const canvas = document.querySelector("canvas.webgl");
    const anchor = document.createElement("a");
    anchor.download = "capture.png";
    anchor.href = canvas.toDataURL();
    anchor.click();
  }
}
