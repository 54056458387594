import { PerspectiveCamera, OrthographicCamera } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import Experience from "./Experience";
import gsap from "gsap";

export default class Camera {
  constructor(isOrtographic = false, controlsEnabled = false) {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.canvas = this.experience.canvas;
    this.debug = this.experience.debug;
    this.gsap = gsap;

    this.setInstance(isOrtographic);

    if (controlsEnabled) {
      this.setOrbitControls();
    }

    this.setDebug();
  }

  setInstance(isOrtographic = false) {
    if (isOrtographic) {
      const frustumSize = 15;
      const aspect = this.sizes.height / this.sizes.width;
      this.instance = new OrthographicCamera(-0.5, 0.5, 0.5, -0.5, 0.1, 1000);
      this.instance.position.set(0, 0, -3);
      this.instance.lookAt(0, 0, 0);
      this.scene.add(this.instance);
    } else {
      this.instance = new PerspectiveCamera(
        35,
        this.sizes.width / this.sizes.height,
        0.1,
        100
      );
      this.instance.position.set(0, -0.4, 0.5);
      this.instance.lookAt(0, 0, 0);
      this.scene.add(this.instance);
    }
  }

  setOrbitControls() {
    this.controls = new OrbitControls(this.instance, this.canvas);
    this.controls.enableDamping = true;
  }

  setDebug() {
    this.enableButton = this.debug.actionsFolder
      .addButton({
        title: "edit camera view",
      })
      .on("click", () => {
        this.enableOrbitControls();
      });
    this.resetButton = this.debug.actionsFolder
      .addButton({
        title: "reset camera",
        hidden: true,
      })
      .on("click", () => {
        this.resetCamera();
      });
  }

  enableOrbitControls() {
    console.log("working");
    this.controls = new OrbitControls(this.instance, this.canvas);
    this.controls.enableDamping = true;
    this.enableButton.hidden = true;
    this.resetButton.hidden = false;
    this.gsap.to(this.instance.position, {
      x: 0,
      y: -2,
      z: 1,
      duration: 1,
    });
    this.updateCursor();
  }

  resetCamera() {
    this.controls.dispose();
    this.enableButton.hidden = false;
    this.resetButton.hidden = true;
    this.gsap.to(this.instance.position, {
      x: 0,
      y: -0.4,
      z: 0.5,
      duration: 1,
    });

    this.resetCursor();
  }

  updateCursor() {
    this.experience.canvas.style.cursor =
      'url("images/orbit-cursor.png"), auto';
  }

  resetCursor() {
    this.experience.canvas.style.cursor = "auto";
  }

  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height;
    this.instance.updateProjectionMatrix();
  }

  update() {
    if (this.controls) {
      this.controls.update();
    }
  }
}
