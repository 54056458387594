import { Pane } from "tweakpane";

export default class Debug {
  constructor() {
    this.pane = new Pane({
      title: "Mesh Gradient",
    });
    this.renderFolder = this.pane.addFolder({ title: "Render" });
    this.gradientFolder = this.pane.addFolder({ title: "Gradient Colors" });
    this.noiseFolder = this.pane.addFolder({ title: "Noise" });
    this.actionsFolder = this.pane.addFolder({ title: "Actions" });
  }
}
